import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, NonIdealState, H5, Checkbox } from '@blueprintjs/core';

import { AuthContext } from '../context/authContext';
import Navigation from '../components/Navigation';
import { Permission, User } from '../types/user';
import useCollection from '../hooks/useCollection';
import { firestoreService } from '../services/firebase';

const Admin: React.FC = () => {
  const { firestoreUser } = useContext(AuthContext);
  const users = useCollection<User>('users');

  const unAuthorizedAction = (
    <Link to="/">
      <Button text="Go to dashboard" />
    </Link>
  );

  // Show an error message if a non-admin user tries to see the page.
  if (!firestoreUser) {
    return (
      <NonIdealState
        icon="office"
        title="Unauthorized"
        description="This page can only be viewed by administrators"
        action={unAuthorizedAction}
      />
    );
  }

  const onChange = (user: User, permissionName: Permission, permissionValue: boolean) => {
    let permissions = [...user.permissions];
    if (permissionValue) {
      permissions.push(permissionName);
    } else {
      permissions = permissions.filter((p) => p !== permissionName);
    }
    firestoreService.collection('users').doc(user.id).update({
      permissions,
    });
  };
  // TODO: Employees should go in a seperate view.

  return (
    <div className="admin">
      <Navigation title="Admin" />
      <div className="content">
        <Card>
          <H5>Permissions</H5>
          <table className="bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-interactive">
            <thead>
              <tr>
                <th>Naam</th>
                <th>E-mailadres</th>
                {Object.values(Permission).map((permission) => (
                  <th key={permission}>{permission}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.displayName}</td>
                  <td>{user.email}</td>
                  {Object.values(Permission).map((permission) => (
                    <td key={permission}>
                      <Checkbox
                        checked={(user.permissions || []).includes(permission)}
                        onChange={(e) => onChange(user, permission, e.currentTarget.checked)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

export default Admin;
