export enum Permission {
  Invoices = 'invoices',
  Portal = 'portal',
  Assessment = 'assessment',
  Timesheets = 'timesheets',
  Declaratie = 'declaratie',
  ResumeBuilder = 'resume-builder',
}

export interface User {
  displayName: string;
  email: string;
  id: string;
  photoURL: string;
  permissions: Array<Permission>;
}
