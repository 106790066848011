import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBVZDULo9jBTE0IkrTCTwjSattunX-hiqs',
  authDomain: 'codecapi-portal.firebaseapp.com',
  databaseURL: 'https://codecapi-portal.firebaseio.com',
  projectId: 'codecapi-portal',
  storageBucket: 'codecapi-portal.appspot.com',
  messagingSenderId: '660681043361',
  appId: '1:660681043361:web:7df8a40b6c11ac1f0dafe0',
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestoreService = firebase.firestore();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// (window as any).firestore = firestore;
