import React, { useContext } from 'react';

import { AuthContext } from '../context/authContext';
import Navigation from '../components/Navigation';
import { Card } from '@blueprintjs/core';
import { sites } from '../sites';

interface PageCardProps {
  page: typeof sites[number];
  onMouseEnter?: any;
  onMouseLeave?: any;
}
const PageCard: React.FC<PageCardProps> = (props) => {
  const { page, onMouseEnter, onMouseLeave } = props;
  return (
    <Card
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => window.open(page.href, '_blank')}>
      <div className="background" style={{ backgroundImage: `url(${page.imageSrc})` }}>
        <div className="dimmer" />
      </div>
      <div className="title">{page.title}</div>
    </Card>
  );
};

const Dashboard: React.FC = () => {
  const { firestoreUser } = useContext(AuthContext);

  const [hoveredPage, setHoveredPage] = React.useState<null | typeof sites[number]>(null);

  if (!firestoreUser) {
    return null;
  }

  const allowedPages = sites.filter((page) =>
    page.requiredPermission
      .map((permission) => firestoreUser.permissions.includes(permission))
      .every((p) => p),
  );
  return (
    <div className="dashboard">
      <Navigation title="" />
      <div className="content" style={{ width: '100%' }}>
        <div className={'zoomable' + (hoveredPage ? ' active' : '')}>
          {allowedPages.map((page) => (
            <PageCard
              onMouseEnter={() => setHoveredPage(page)}
              onMouseLeave={() => setHoveredPage(null)}
              key={page.title}
              page={page}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
