import React from 'react';
import GoogleSignInButton from '../components/GoogleSignInButton';
import { AuthContext } from '../context/authContext';
import { Spinner } from '@blueprintjs/core';

const Login: React.FC = () => {
  const { isLoading, user, firestoreUser } = React.useContext(AuthContext);

  const loading = isLoading || !!(user && !firestoreUser);

  return (
    <div className="login">
      <h1>CodeCapi Portal</h1>
      <p>Gebruik je CodeCapi e-mailadres om in te loggen.</p>
      <GoogleSignInButton disabled={loading} />
      {loading && <Spinner />}
    </div>
  );
};

export default Login;
