import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthContext, AuthProvider } from './context/authContext';
import { FocusStyleManager } from '@blueprintjs/core';

import PrivateRoute from './components/PrivateRoute';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import Admin from './views/Admin';

const Routes: React.FC = () => {
  const { firestoreUser } = React.useContext(AuthContext);
  if (!firestoreUser) {
    return <Login />;
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/admin" component={Admin} />
      </Switch>
    </Router>
  );
};

const App: React.FC = () => {
  FocusStyleManager.onlyShowFocusOnTabs();

  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
};

export default App;
