import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Alignment,
  Button,
  Classes,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Position,
  Tooltip,
} from '@blueprintjs/core';

import { AuthContext } from '../context/authContext';
import { Permission } from '../types/user';

interface NavigationProps {
  title: string;
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const { firestoreUser, logout } = useContext(AuthContext);
  const { title } = props;
  const fullTitle = title.length ? `CodeCapi Portal - ${title}` : 'CodeCapi Portal';

  const adminLink = firestoreUser?.permissions.includes(Permission.Portal) ? (
    <Link to="/admin">
      <Tooltip content="Admin" position={Position.BOTTOM}>
        <Button className={Classes.MINIMAL} icon="office" text="" />
      </Tooltip>
    </Link>
  ) : (
    ''
  );

  return (
    <Navbar fixedToTop>
      <NavbarGroup align={Alignment.LEFT}>
        <NavbarHeading>
          <Link to="/">{fullTitle}</Link>
        </NavbarHeading>
        {/* <NavbarDivider /> */}
      </NavbarGroup>
      <NavbarGroup align={Alignment.RIGHT}>
        <span>{firestoreUser?.displayName || ''}</span>
        <NavbarDivider />
        {adminLink}
        <Tooltip content="Log out" position={Position.BOTTOM}>
          <Button onClick={logout} className={Classes.MINIMAL} icon="log-out" text="" />
        </Tooltip>
      </NavbarGroup>
    </Navbar>
  );
};

export default Navigation;
