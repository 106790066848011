import React, { createContext, useState, useEffect } from 'react';
import { firestore, User as FirebaseUser } from 'firebase';

import { User } from '../types/user';
import { firestoreService, auth, googleAuthProvider } from '../services/firebase';

type Props = {
  children: React.ReactNode;
};

type ContextProps = {
  user: FirebaseUser | null;
  firestoreUser: User | null;
  isLoading: boolean;
  login: () => Promise<any>;
  logout: () => Promise<any>;
};

const initialContext: ContextProps = {
  user: null,
  firestoreUser: null,
  isLoading: true,
  login: async () => null,
  logout: async () => null,
};

export const AuthContext = createContext<ContextProps>(initialContext);

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState(initialContext.user);
  const [firestoreUser, setFirestoreUser] = useState(initialContext.firestoreUser);
  const [isLoading, setIsLoading] = useState(true);

  const login = () => auth.signInWithPopup(googleAuthProvider);
  const logout = () => {
    setFirestoreUser(null);
    setUser(null);
    return auth.signOut();
  };

  const init = async () => {
    // Check if the user is logged in. And set the currentUser based on the response.
    let user = auth.currentUser;
    if (!user) {
      const result = await auth.getRedirectResult();
      user = result.user;
    }
    if (user) {
      (firestoreService.collection('users').doc(user.uid) as firestore.DocumentReference<
        User
      >).onSnapshot(
        (snap) => {
          if (snap.exists) {
            setFirestoreUser(snap.data() || null);
          }
        },
        (error) => {
          setFirestoreUser(null);
        },
      );
      setUser(user);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    auth.onAuthStateChanged(init);
  }, []);

  return (
    <AuthContext.Provider value={{ user, firestoreUser, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
