import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import Loader from './Loader';

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: RouteComponent, ...rest }) => {
  const { firestoreUser, isLoading } = useContext(AuthContext);

  // Display loading content until the request is done.
  if (isLoading) {
    return <Loader />;
  }

  // Show the component if the user is logged in, otherwise redirect the user to /login.
  return (
    <Route
      {...rest}
      render={(props) =>
        !!firestoreUser ? <RouteComponent {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
