import { Permission } from './types/user';

interface Site {
  requiredPermission: Array<Permission>;
  title: string;
  href: string;
  imageSrc: string;
}

export const sites: Array<Site> = [
  {
    title: 'Facturatie',
    href: 'https://invoices.codecapi.nl',
    imageSrc: '/codecapi.png',
    requiredPermission: [Permission.Invoices],
  },
  {
    title: 'Clockify',
    href: 'https://clockify.me/timesheet',
    imageSrc: '/clockify.png',
    requiredPermission: [],
  },
  {
    title: 'Drive',
    href: 'https://drive.codecapi.nl',
    imageSrc: '/drive.png',
    requiredPermission: [],
  },
  {
    title: 'Agenda',
    href: 'https://agenda.codecapi.nl',
    imageSrc: '/calendar.png',
    requiredPermission: [],
  },
  {
    title: 'Mail',
    href: 'https://mail.codecapi.nl',
    imageSrc: '/gmail.png',
    requiredPermission: [],
  },
  {
    title: 'Assessment',
    href: 'https://assessment.codecapi.com',
    imageSrc: '/codecapi.png',
    requiredPermission: [Permission.Assessment],
  },
  {
    title: 'Timesheets',
    href: 'https://timesheets.codecapi.nl',
    imageSrc: '/codecapi.png',
    requiredPermission: [Permission.Timesheets],
  },
  {
    title: 'Declaratie',
    href: 'https://declaraties.codecapi.nl',
    imageSrc: '/codecapi.png',
    requiredPermission: [],
  },
  {
    title: 'CV',
    href: 'https://resume.codecapi.nl',
    imageSrc: '/codecapi.png',
    requiredPermission: [Permission.ResumeBuilder],
  },
];
