import React from 'react';
import { firestore } from 'firebase';
import { firestoreService } from '../services/firebase';

const useCollection = <T extends object = { id: string }>(collection: string) => {
  const [items, setItems] = React.useState<T[]>([]);
  React.useEffect(() => {
    const query = firestoreService.collection(collection) as firestore.CollectionReference<T>;
    return query.onSnapshot(
      (querySnapshot) => {
        setItems(querySnapshot.docs.map((doc) => doc.data()));
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      },
    );
  }, [collection]);
  return items;
};

export default useCollection;
